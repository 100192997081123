export const calculateMSRP = (
  pMonthlyEstimatedPayment: number,
  pApr: number,
  pLoanTerm: number,
  downPayment: number,
  tradeInValue: number,
  incentivesTotal: number
) => {
  const rate = pApr / 100;
  let pTotalAfterDeductions = 0;

  if (pApr > 0) {
    pTotalAfterDeductions = Math.floor(
      Math.max(
        0,
        (pMonthlyEstimatedPayment * (1 - (1 + rate / 12) ** -pLoanTerm)) /
          (rate / 12)
      )
    );
  } else {
    pTotalAfterDeductions = Math.max(0, pMonthlyEstimatedPayment * pLoanTerm);
  }

  let newSalePrice = pTotalAfterDeductions + downPayment + tradeInValue;

  if (incentivesTotal > 0) {
    newSalePrice -= incentivesTotal;
  }

  return newSalePrice;
};
