import { FC } from 'react';
import { PaymentCalculator } from '@/components/molecules/PaymentCalculator/PaymentCalculator';
import ClientPortal from '@/components/molecules/ClientPortal';
import { PaymentCalculatorProps } from '@/types';

export interface PaymentCalculatorModalProps
  extends Omit<PaymentCalculatorProps, 'setMode' | 'mode'> {
  open: boolean;
  close: () => void;
  setCalculatedMaxMonthlyPayment?: (maxMonthlyPayment: string) => void;
  setCalculatedMSRP?: (msrp: string) => void;
  showSearchBudgetButton?: boolean;
  enteredMaxMonthlyPayment?: string;
  totalIncentiveAmount: number;
}

export const PaymentCalculatorModal: FC<PaymentCalculatorModalProps> = (
  props
) => {
  const {
    open,
    close,
    setCalculatedMaxMonthlyPayment,
    setCalculatedMSRP,
    showSearchBudgetButton,
    enteredMaxMonthlyPayment,
  } = props;

  return (
    <ClientPortal
      open={open}
      closeFn={close}
      className="flex max-h-[90vh] w-fit-content max-w-[90vw] rounded-small bg-white !p-0"
    >
      <PaymentCalculator
        isModal={true}
        setCalculatedMaxMonthlyPayment={setCalculatedMaxMonthlyPayment}
        setCalculatedMSRP={setCalculatedMSRP}
        showSearchBudgetButton={showSearchBudgetButton}
        enteredMaxMonthlyPayment={enteredMaxMonthlyPayment}
        {...props}
      />
    </ClientPortal>
  );
};
