import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import { CALCULATOR_DISCLAIMER } from '@/lib/constants';
import { PaymentCalculatorProps } from '@/types';
import { useEffect, useState } from 'react';
import { FinanceCalculator } from './FinanceCalculator';
import { LeaseCalculator } from './LeaseCalculator';

export const LOAN_TERM_OPTIONS = ['36', '48', '60', '72', '84'];
const INITIAL_APR = 5;
const INITIAL_DOWN_PAYMENT_PERCENT = 0.2;

const LEASE_TERM_OPTIONS = ['12', '24', '36', '48', '60'];
const INITIAL_RESIDUAL_VALUE_PERCENT = 0.5;
const MILAGE_OPTIONS = [
  { text: '7,500 miles or less', value: '-1' },
  { text: 'Up to 10,000 miles', value: '1' },
  { text: 'Up to 12,000 miles', value: '2' },
  { text: 'More than 12,000 miles', value: '3' },
];

export const PaymentCalculator = (props: PaymentCalculatorProps) => {
  const [mode, setMode] = useState<'Finance' | 'Lease'>('Finance');

  const [downPaymentType, setDownPaymentType] = useState('%');
  const [salePrice, setSalePrice] = useState<number>(props.productPrice || 0);
  const [downPayment, setDownPayment] = useState<number>(
    Math.floor(INITIAL_DOWN_PAYMENT_PERCENT * (props.productPrice || 0))
  );
  const [downPaymentPercent, setDownPaymentPercent] = useState<number>(
    INITIAL_DOWN_PAYMENT_PERCENT * 100
  );
  // lease state
  const [milageFactorIndex, setMilageFactorIndex] = useState<number>(1);
  const [residualValueType, setResidualValueType] = useState('%');
  const [residualPercent, setResidualPercent] = useState<number>(
    INITIAL_RESIDUAL_VALUE_PERCENT * 100
  );
  const [residualValue, setResidualValue] = useState<number>(
    Math.floor(INITIAL_RESIDUAL_VALUE_PERCENT * (props.productPrice || 0))
  );
  const [leaseTerm, setLeaseTerm] = useState<string>(LEASE_TERM_OPTIONS[2]);

  // finance state
  const [tradeInValue, setTradeInValue] = useState<number>(0);
  const [loanTerm, setLoanTerm] = useState<string>(LOAN_TERM_OPTIONS[2]);

  const [apr, setApr] = useState<number>(INITIAL_APR);
  const [monthlyBudget, setMonthlyBudget] = useState<number>(
    salePrice / Number(loanTerm)
  );

  useEffect(() => {
    if (mode === 'Finance') {
      props.onChangeTerm?.(loanTerm);
    } else if (mode === 'Lease') {
      props.onChangeTerm?.(leaseTerm);
    }
  });

  const sharedProps = {
    ...props,
    downPaymentType,
    setDownPaymentType,
    salePrice,
    setSalePrice,
    downPayment,
    setDownPayment,
    downPaymentPercent,
    setDownPaymentPercent,
    apr,
    setApr,
    incentivesTotal: props.totalIncentiveAmount,
    mode,
    setMode,
    isModal: props.isHomePage || props.isModal,
  };

  const financeCalculatorProps = {
    ...sharedProps,
    tradeInValue,
    setTradeInValue,
    loanTermOptions: LOAN_TERM_OPTIONS,
    loanTerm,
    setLoanTerm,
    monthlyBudget,
    setMonthlyBudget,
  };

  const leaseCalculatorProps = {
    ...sharedProps,
    milageFactorIndex,
    setMilageFactorIndex,
    milageOptions: MILAGE_OPTIONS,
    residualValueType,
    setResidualValueType,
    residualPercent,
    setResidualPercent,
    residualValue,
    setResidualValue,
    leaseTerm,
    setLeaseTerm,
    leaseTermOptions: LEASE_TERM_OPTIONS,
  };

  return (
    <div
      className={`flex h-full w-full flex-col rounded-small border border-neutral-200 ${props.isHomePage ? 'rounded-small shadow-xl' : ''}`}
    >
      <div className="flex h-full w-full">
        {mode === 'Finance' ? (
          <FinanceCalculator {...financeCalculatorProps} />
        ) : (
          <LeaseCalculator {...leaseCalculatorProps} />
        )}
      </div>
      {props.showSearchBudgetButton && (
        <div
          className={`flex w-full bg-white ${props.isHomePage ? 'h-content rounded-b-small border-t' : 'border-b border-l border-r'} ${props.isModal ? '' : 'border-neutral-200'}`}
        >
          {!props.isHomePage && (
            <div className="flex w-full p-xl pb-xl pt-l">
              <div className="text-microLight text-neutral-800">
                {CALCULATOR_DISCLAIMER}
              </div>
            </div>
          )}
          <div
            className={`flex w-full p-3xl py-xl ${props.isHomePage ? '' : 'border-t-[1px] border-neutral-200'}`}
          >
            <Button
              aria-label="Search your budget"
              variant={ButtonVariants.Tertiary}
              disabled={
                !apr ||
                !salePrice ||
                downPayment >= salePrice ||
                tradeInValue >= salePrice
              }
              onClick={() => {
                window.location.href = `/search/price_end/${Math.max(
                  0,
                  salePrice
                )}/sort/year_desc`;
              }}
            >
              Search your budget
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
