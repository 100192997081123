import {
  DistanceFilterValue,
  FetchListingQueryStringParams,
  FilterType,
} from '@/types';
import { milesToNearestKilometer } from '../conversion';
import { DEFAULT_SEARCH_MILEAGE } from '../constants';

export const getValidDistanceFilterValueOrDefault = (
  distance: string
): DistanceFilterValue =>
  distance === '50' ||
  distance === '100' ||
  distance === '150' ||
  distance === '200' ||
  distance === 'nationwide'
    ? distance
    : DEFAULT_SEARCH_MILEAGE;

export const mapFilterValuesToQueryParamsString = (
  filters: FilterType
): string => {
  const { distance, fuelType, maxMileage, ...rest } = filters;
  const queryStringParamToFilterValue: FetchListingQueryStringParams = {
    ...rest,
    model: filters.model.length ? filters.model.join(',') : '',
    make: filters.make.length ? filters.make.join(',') : '',
    max_mileage: maxMileage,
    condition:
      filters.condition.charAt(0).toUpperCase() + filters.condition.slice(1),
    fuel_type: fuelType.charAt(0).toUpperCase() + fuelType.slice(1),
    radius_km:
      distance !== 'nationwide'
        ? milesToNearestKilometer(distance || '50')
        : undefined,
    body: filters.body.join(','),
  };

  const encodedQueryString = Object.entries(queryStringParamToFilterValue)
    .reduce((acc, [key, value]) => {
      if (value) {
        acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
      return acc;
    }, [] as string[])
    .join('&');

  return encodedQueryString;
};

export const generateSearchSlug = ({
  year,
  make,
  model,
  style,
  postal,
  country,
  incentives,
  showRebates,
  vector_search,
}: {
  year?: string;
  make?: string;
  model?: string;
  style?: string;
  postal?: string;
  country?: string;
  incentives?: boolean;
  showRebates?: boolean;
  vector_search: string;
}) => {
  let slug = '';
  if (year) {
    slug += `/year_start/${encodeURIComponent(year)}`;
  }
  if (postal && country === 'US') {
    slug = `/postal/${postal}`;
  }
  if (make) {
    slug += `/make/${encodeURIComponent(make)}`;
  }
  if (model) {
    slug += `/model/${encodeURIComponent(model)}`;
  }
  if (style) {
    slug += `/style/${encodeURIComponent(style)}`;
  }
  if (incentives) {
    slug += `/has_ev_incentives/${incentives}`;
  }
  if (showRebates) {
    slug += `/show_rebates/${showRebates}`;
  }
  if (vector_search) {
    slug += `/vector_search/${encodeURIComponent(vector_search)}`;
  }

  return slug;
};

export const handleFilteredSearch = async ({
  input,
  zip = '',
  country = '',
  incentives,
  showRebates,
}: {
  input: string;
  zip?: string;
  country?: string;
  incentives?: boolean;
  showRebates?: boolean;
}) => {
  let targetUrl = '/search/';
  const slugArgs = {
    postal: zip,
    country,
    incentives,
    showRebates,
    vector_search: input,
  };
  const searchSlug = generateSearchSlug(slugArgs);

  targetUrl = `/search/${searchSlug}`;
  window.location.href = targetUrl;
};
