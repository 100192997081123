export interface BaseProductListingLinkProps {
  isColumnLayout?: boolean;
  zipcode?: string;
}

export const VDPLoadingCard = () => {
  return (
    <div className="flex h-[100vh] w-full flex-col">
      <div className="align-items-start flex h-full w-full animate-pulse flex-col gap-l p-xl">
        <div className="h-[24px] max-w-[140px] rounded-small bg-loading-gradient" />
        <div className="h-[24px] max-w-[200px] rounded-small bg-loading-gradient" />
        <div className="flex h-[60%] gap-l">
          <div className="flex h-full w-full rounded-small bg-loading-gradient m:w-3/5" />
          <div className="hidden h-full w-2/5 flex-col gap-l m:flex">
            <div className="flex h-full w-full rounded-small bg-loading-gradient" />
            <div className="flex h-full w-full rounded-small bg-loading-gradient" />
          </div>
        </div>
        <div className="flex h-[10%] w-full rounded-small bg-loading-gradient" />

        <div className="mb-l h-xl max-w-[600px] rounded-small bg-loading-gradient" />
      </div>
    </div>
  );
};

export const LoadingProductListingLink: React.FC<
  BaseProductListingLinkProps
> = ({ isColumnLayout }) => {
  return (
    <div
      className={`flex ${isColumnLayout ? 'flex-col' : ''} flex-grow rounded-small border border-neutral-200`}
    >
      <div
        className={`flex ${isColumnLayout ? 'h-[40%] w-full' : 'h-full w-[40%]'} animate-pulse bg-loading-gradient`}
      />
      <div className="flex flex-grow flex-col justify-between p-l">
        <div className="flex flex-col gap-xl pt-xl">
          <div className="flex h-[32px] w-full animate-pulse rounded-small bg-loading-gradient" />
          <div className="flex h-[32px] w-full animate-pulse rounded-small bg-loading-gradient" />
        </div>
        <div className="flex h-[48px] w-full rounded-small bg-loading-gradient" />
      </div>
    </div>
  );
};
export const VDPComponentLoadingCard = () => {
  return (
    <div className="animate-pulse flex-col p-l">
      <div className="mb-l h-[40px] rounded-small bg-loading-gradient p-xl" />
    </div>
  );
};
