import { Dropdown } from '@/components/atoms/Dropdown/Dropdown';
import { Input, InputVariants } from '@/components/atoms/Input/Input';
import { Switcher } from '@/components/atoms/Switcher/Switcher';
import useWindowSize from '@/hooks/useWindowSize';
import { calculatedMonthlyPaymentLease } from '@/lib/calculatedMonthlyPayment';
import { calculateMSRP } from '@/lib/calculateMSRP';
import { INCENTIVES_DISCLAIMER } from '@/lib/constants';
import { PaymentCalculatorProps } from '@/types';
import { useCallback, useEffect, useState } from 'react';
import Accordion from '../Accordion';
import {
  BreakdownItem,
  BreakdownSectionDivider,
  CalculatorWrapper,
  Total,
} from './Wrappers';

interface LeaseCalculatorProps extends PaymentCalculatorProps {
  mode?: 'Finance' | 'Lease';
  setMode?: (mode: 'Finance' | 'Lease') => void;
  downPaymentType: string;
  setDownPaymentType: (val: '%' | '$') => void;
  downPayment: number;
  setDownPayment: (val: number) => void;
  downPaymentPercent: number;
  setDownPaymentPercent: (val: number) => void;
  salePrice: number;
  setSalePrice: (val: number) => void;
  milageFactorIndex: number;
  setMilageFactorIndex: (val: number) => void;
  milageOptions: { text: string; value: string }[];
  residualValueType: string;
  setResidualValueType: (val: '%' | '$') => void;
  residualPercent: number;
  setResidualPercent: (val: number) => void;
  residualValue: number;
  setResidualValue: (val: number) => void;
  leaseTerm: string;
  setLeaseTerm: (va: string) => void;
  leaseTermOptions: string[];
  apr: number;
  setApr: (val: number) => void;
  incentivesTotal: number;
}

export const LeaseCalculator = ({
  expanded = true,
  setExpanded,
  isModal = false,
  showSearchBudgetButton = true,
  onChangeApr,
  onChangeMonthlyEstimatedPayment,
  setCalculatedMaxMonthlyPayment,
  setCalculatedMSRP,
  enteredMaxMonthlyPayment = '',
  downPaymentType,
  setDownPaymentType,
  salePrice,
  setSalePrice,
  downPayment,
  setDownPayment,
  downPaymentPercent,
  setDownPaymentPercent,
  milageFactorIndex,
  setMilageFactorIndex,
  milageOptions,
  residualValueType,
  setResidualValueType,
  residualPercent,
  setResidualPercent,
  residualValue,
  setResidualValue,
  leaseTerm,
  setLeaseTerm,
  leaseTermOptions: LEASE_TERM_OPTIONS,
  apr,
  setApr,
  incentivesTotal,
  fixedPrice = true,
  handleOfferButton,
  mode = 'Lease',
  setMode,
  isHomePage,
  fuelSavings,
  onChangeTerm,
}: LeaseCalculatorProps) => {
  const { isTablet, isMobile } = useWindowSize();

  const calculateMonthlyEstimatedPayment = useCallback(
    (
      pApr: number,
      pTotalAfterDeductions: number,
      pLeaseTerm: number,
      pResidualValue: number
    ) => {
      return calculatedMonthlyPaymentLease(
        pApr,
        pTotalAfterDeductions,
        pLeaseTerm,
        pResidualValue,
        fuelSavings
          ? {
              estimate: Number(fuelSavings.fuelSavingsEstimate),
              timePeriod: Number(fuelSavings.timePeriod),
            }
          : undefined
      );
    },
    [fuelSavings]
  );

  const calculateSalePrice = useCallback(
    (pMonthlyEstimatedPayment: number, pApr: number, pLoanTerm: number) => {
      return calculateMSRP(
        pMonthlyEstimatedPayment,
        pApr,
        pLoanTerm,
        downPayment,
        0,
        incentivesTotal
      );
    },
    [downPayment, incentivesTotal]
  );

  const calculateTotalAfterDeductions = useCallback(
    (pSalePrice: number, pDownPayment: number, pIncentivesTotal = 0) => {
      let currentTotal = 0;
      if (pSalePrice > 0) {
        currentTotal = pSalePrice - pDownPayment;
        if (pIncentivesTotal > 0) {
          currentTotal -= pIncentivesTotal;
        }
      }
      return currentTotal;
    },
    []
  );

  const [totalAfterDeductions, setTotalAfterDeductions] = useState<number>(
    calculateTotalAfterDeductions(salePrice, downPayment, incentivesTotal)
  );

  const [monthlyEstimatedPayment, setMonthlyEstimatedPayment] =
    useState<number>(
      enteredMaxMonthlyPayment
        ? Number(enteredMaxMonthlyPayment)
        : calculateMonthlyEstimatedPayment(
            apr,
            totalAfterDeductions,
            Number(leaseTerm),
            residualValue
          )
    );

  const handleChangeMonthlyEstimatedPayment = useCallback(
    (inputMonthlyEstimatedPayment: string) => {
      setMonthlyEstimatedPayment(Number(inputMonthlyEstimatedPayment));
      setCalculatedMaxMonthlyPayment?.(inputMonthlyEstimatedPayment);

      const newSP = calculateSalePrice(
        Number(inputMonthlyEstimatedPayment),
        apr,
        Number(leaseTerm)
      );

      setSalePrice(newSP);
      setCalculatedMSRP?.(newSP.toString());
      const newTotalAfterDeductions = calculateTotalAfterDeductions(
        newSP,
        downPayment,
        incentivesTotal
      );
      setTotalAfterDeductions(newTotalAfterDeductions);
    },

    [
      setCalculatedMaxMonthlyPayment,
      calculateSalePrice,
      apr,
      leaseTerm,
      setSalePrice,
      setCalculatedMSRP,
      calculateTotalAfterDeductions,
      downPayment,
      incentivesTotal,
    ]
  );

  const updateInputs = useCallback(
    (
      newTotalAfterDeductions: number,
      newApr: number,
      newLeaseTerm: number,
      newResidualValue: number
    ) => {
      const newMonthlyEstimatedPayment = calculatedMonthlyPaymentLease(
        newApr,
        newTotalAfterDeductions,
        newLeaseTerm,
        newResidualValue
      );
      setMonthlyEstimatedPayment(newMonthlyEstimatedPayment);
      setCalculatedMaxMonthlyPayment?.(
        Math.round(newMonthlyEstimatedPayment).toString()
      );
    },
    [setCalculatedMaxMonthlyPayment]
  );

  useEffect(() => {
    updateInputs(totalAfterDeductions, apr, Number(leaseTerm), residualValue);
  });

  const handleChangeSalePrice = useCallback(
    (pSalePrice: string) => {
      setSalePrice(Number(pSalePrice));
      setCalculatedMSRP?.(pSalePrice);
      const newTotalAfterDeductions = calculateTotalAfterDeductions(
        Number(pSalePrice),
        downPayment,
        incentivesTotal
      );
      setTotalAfterDeductions(newTotalAfterDeductions);
      updateInputs(
        newTotalAfterDeductions,
        apr,
        Number(leaseTerm),
        residualValue
      );
    },
    [
      setSalePrice,
      setCalculatedMSRP,
      calculateTotalAfterDeductions,
      downPayment,
      incentivesTotal,
      updateInputs,
      apr,
      leaseTerm,
      residualValue,
    ]
  );

  const handleChangeApr = useCallback(
    (pApr: string) => {
      const newApr = Number(pApr);
      if (newApr <= 100) {
        setApr(newApr);
        onChangeApr?.(newApr);
        const newTotalAfterDeductions = calculateTotalAfterDeductions(
          salePrice,
          downPayment,
          incentivesTotal
        );
        setTotalAfterDeductions(newTotalAfterDeductions);

        updateInputs(
          newTotalAfterDeductions,
          newApr,
          Number(leaseTerm),
          residualValue
        );
      }
    },
    [
      setApr,
      onChangeApr,
      calculateTotalAfterDeductions,
      salePrice,
      downPayment,
      incentivesTotal,
      updateInputs,
      leaseTerm,
      residualValue,
    ]
  );

  const handleChangeDownPayment = useCallback(
    (pDownPayment: string) => {
      let value = Number(pDownPayment);
      if (downPaymentType === '$') {
        setDownPayment(Number(value.toFixed(0)));
      } else if (value <= 100) {
        setDownPaymentPercent(value);
        value = Math.floor((value / 100) * salePrice);
        setDownPayment(value);
      }

      const newTotalAfterDeductions = calculateTotalAfterDeductions(
        salePrice,
        value,
        incentivesTotal
      );
      setTotalAfterDeductions(newTotalAfterDeductions);

      updateInputs(
        newTotalAfterDeductions,
        apr,
        Number(leaseTerm),
        residualValue
      );
    },
    [
      downPaymentType,
      calculateTotalAfterDeductions,
      salePrice,
      incentivesTotal,
      updateInputs,
      apr,
      leaseTerm,
      residualValue,
      setDownPayment,
      setDownPaymentPercent,
    ]
  );

  const handleChangeDownPaymentType = useCallback(
    (type: string) => {
      setDownPayment(
        Number(((downPaymentPercent * salePrice) / 100).toFixed(0))
      );
      setDownPaymentPercent(
        Math.min(100, Number(((downPayment / salePrice) * 100).toFixed(0)))
      );
      setDownPaymentType(type as '%' | '$');
    },
    [
      downPayment,
      downPaymentPercent,
      salePrice,
      setDownPayment,
      setDownPaymentPercent,
      setDownPaymentType,
    ]
  );

  const handleChangeLeaseTerm = useCallback(
    (pLeaseTerm: string) => {
      setLeaseTerm(pLeaseTerm);
      onChangeTerm?.(pLeaseTerm);
      updateInputs(
        totalAfterDeductions,
        apr,
        Number(pLeaseTerm),
        residualValue
      );
    },
    [
      setLeaseTerm,
      updateInputs,
      onChangeTerm,
      totalAfterDeductions,
      apr,
      residualValue,
    ]
  );

  const handleChangeResidualValue = useCallback(
    (pResidualValue: string) => {
      let value;
      if (residualValueType === '$') {
        value = Number(pResidualValue);
        setResidualValue(Number(pResidualValue));
        setResidualPercent(
          Number(((Number(pResidualValue) / salePrice) * 100).toFixed(0))
        );
      } else {
        value = Number(((Number(pResidualValue) * salePrice) / 100).toFixed(0));
        setResidualPercent(Number(pResidualValue));
        setResidualValue(value);
      }

      updateInputs(totalAfterDeductions, apr, Number(leaseTerm), value);
    },
    [
      residualValueType,
      updateInputs,
      totalAfterDeductions,
      apr,
      leaseTerm,
      setResidualValue,
      setResidualPercent,
      salePrice,
    ]
  );

  const handleChangeResidualValueType = useCallback(
    (type: string) => {
      setResidualValue(
        Number(((residualPercent * salePrice) / 100).toFixed(0))
      );
      setResidualPercent(
        Number(((residualValue / salePrice) * 100).toFixed(0))
      );
      setResidualValueType(type as '%' | '$');
    },

    [
      residualPercent,
      residualValue,
      salePrice,
      setResidualPercent,
      setResidualValue,
      setResidualValueType,
    ]
  );

  const handleChangeMileageFactor = useCallback(
    (newMilageFactorIndex: number) => {
      setMilageFactorIndex(Number(newMilageFactorIndex));
      const newMileageFactor = milageOptions[newMilageFactorIndex].value;
      const newResidualPercent =
        Number(residualPercent) + Number(newMileageFactor);
      const newResidualValue = Number(
        ((newResidualPercent * salePrice) / 100).toFixed(0)
      );
      setResidualPercent(newResidualPercent);
      setResidualValue(newResidualValue);

      updateInputs(
        totalAfterDeductions,
        apr,
        Number(leaseTerm),
        newResidualValue
      );
    },
    [
      setMilageFactorIndex,
      milageOptions,
      residualPercent,
      salePrice,
      setResidualPercent,
      setResidualValue,
      updateInputs,
      totalAfterDeductions,
      apr,
      leaseTerm,
    ]
  );

  const handleChangeMode = useCallback(
    (val: 'Finance' | 'Lease') => {
      if (setMode) {
        setMode(val);
      }
    },
    [setMode]
  );

  useEffect(() => {
    if (onChangeMonthlyEstimatedPayment) {
      onChangeMonthlyEstimatedPayment(monthlyEstimatedPayment);
    }
  }, [monthlyEstimatedPayment, onChangeMonthlyEstimatedPayment]);

  const inputs = (
    <div className="flex flex-col gap-s">
      {fixedPrice ? (
        <Input
          aria-label="Listed price"
          id="salePrice"
          label="Listed price"
          type={InputVariants.Filled}
          value={`$${salePrice.toString()}`}
        />
      ) : (
        <Input
          aria-label="Listed price"
          type={InputVariants.Number}
          value={salePrice.toString()}
          onChange={(val) => handleChangeSalePrice(val)}
          label="Listed price"
          prepend="$"
          placeholder="0"
          step={1000}
        />
      )}
      <Input
        aria-label="Est. Interest rate"
        type={InputVariants.Number}
        value={apr.toString()}
        onChange={(val) => handleChangeApr(val)}
        label="Est. Interest rate %"
        append="%"
        placeholder="8.50"
        step={0.01}
      />
      <Input
        aria-label="Down Payment"
        type={InputVariants.Number}
        value={
          downPaymentType === '$'
            ? downPayment.toString()
            : downPaymentPercent.toString()
        }
        onChange={(val) => handleChangeDownPayment(val)}
        invalid={
          (downPaymentType === '$' && downPayment >= salePrice) ||
          (downPaymentType === '%' &&
            (downPaymentPercent >= 100 || downPaymentPercent < 0))
        }
        label="Down Payment"
        float={0}
        placeholder="0"
        suffixOption={{
          value: downPaymentType,
          options: [
            { label: '$', value: '$' },
            { label: '%', value: '%' },
          ],
          setSuffixOption: (val) => handleChangeDownPaymentType(val),
        }}
      />
      {((downPaymentType === '$' && downPayment >= salePrice) ||
        (downPaymentType === '%' &&
          (downPaymentPercent >= 100 || downPaymentPercent < 0))) && (
        <div className="text-microMedium text-red-medium">
          Must be less than sale price
        </div>
      )}

      <Dropdown
        valueIndex={milageFactorIndex}
        label="Annual mileage"
        options={milageOptions}
        onChange={(val) => {
          handleChangeMileageFactor(val);
        }}
      />

      <div>
        <div className="label mb-xs block text-body2Medium text-neutral-800">
          Lease Term in months
        </div>
        <Switcher
          aria-label="Lease Term in months"
          options={LEASE_TERM_OPTIONS.map((option) => ({
            text: option,
            value: option,
          }))}
          value={leaseTerm}
          onChange={(e) => handleChangeLeaseTerm(e)}
        />
      </div>

      <Input
        aria-label="Residual value of the vehicle"
        type={InputVariants.Number}
        value={
          residualValueType === '$'
            ? residualValue.toString()
            : residualPercent.toString()
        }
        onChange={(val) => handleChangeResidualValue(val)}
        invalid={
          (residualValueType === '$' && residualValue >= salePrice) ||
          (residualValueType === '%' &&
            (residualPercent >= 100 || residualPercent < 0))
        }
        label="Residual value"
        float={0}
        suffixOption={{
          value: residualValueType,
          options: [
            { label: '$', value: '$' },
            { label: '%', value: '%' },
          ],
          setSuffixOption: (val) => handleChangeResidualValueType(val),
        }}
      />
      {((residualValueType === '$' && residualValue >= salePrice) ||
        (residualValueType === '%' &&
          (residualPercent >= 100 || residualPercent < 0))) && (
        <div className="text-microMedium text-red-medium">
          Must be less than sale price
        </div>
      )}
    </div>
  );

  const breakdown = (
    <div className="flex flex-col gap-s">
      <BreakdownItem
        label="Listed price"
        val={`$${salePrice.toLocaleString()}`}
      />

      <BreakdownSectionDivider />

      <BreakdownItem
        label="Est. Interest rate"
        val={`${apr.toFixed(2).toLocaleString()}%`}
      />
      <BreakdownItem
        label="Down Payment"
        val={`${downPayment > 0 ? '-' : ''}$${downPayment.toLocaleString()}`}
      />

      {incentivesTotal || fuelSavings ? <BreakdownSectionDivider /> : null}

      {incentivesTotal ? (
        <BreakdownItem
          label="Incentives and Rebates"
          val={`${incentivesTotal > 0 ? '-' : ''}$${
            incentivesTotal > 0 ? incentivesTotal.toLocaleString() : 0
          }`}
          tooltip={INCENTIVES_DISCLAIMER}
        />
      ) : null}
      {fuelSavings ? (
        <BreakdownItem
          label={`Fuel Savings over ${Number(leaseTerm) / 12} years`}
          val={`-$${(
            (Number(fuelSavings.fuelSavingsEstimate) *
              (Number(leaseTerm) / 12)) /
            Number(fuelSavings.timePeriod)
          ).toLocaleString()}`}
          tooltip={`Efficiency assessments are guided by EPA standards, factoring in 45% highway and 55% city driving for ${Intl.NumberFormat('en-US').format(Number(fuelSavings.annualMiles))} annual miles. Costs are based on national averages: $${Number(fuelSavings.gasPerGallon).toFixed(2)} per gallon of gasoline and $${Number(fuelSavings.electricityPerKwh).toFixed(2)} per kWh for electricity. Actual savings may vary due to market changes and driving conditions. Consumption ratings are provided for both electric vehicles (in kWh/100mi) and gasoline vehicles (in MPG).`}
        />
      ) : null}

      <BreakdownSectionDivider strong />

      <BreakdownItem
        label="Total lease amount"
        val={`$${totalAfterDeductions.toLocaleString()}`}
      />

      <Total
        label="Total"
        monthlyTotal={monthlyEstimatedPayment}
        overallTotal={monthlyEstimatedPayment * Number(leaseTerm)}
      />

      <BreakdownSectionDivider />

      <BreakdownItem
        label="Residual value of the vehicle"
        val={`$${residualValue.toLocaleString()}`}
      />
    </div>
  );

  return (
    <CalculatorWrapper
      inputs={
        isMobile || (isTablet && isModal) ? (
          <Accordion
            title={'Customize Payments'}
            buttonClassName="text-neutral-900"
            icon={{ id: 'chevron' }}
            expanded={expanded}
            setExpanded={setExpanded}
          >
            {inputs}
          </Accordion>
        ) : (
          inputs
        )
      }
      breakdown={breakdown}
      monthlyEstimatedPayment={monthlyEstimatedPayment}
      handleChangeMonthlyEstimatedPayment={handleChangeMonthlyEstimatedPayment}
      handleOfferButton={handleOfferButton}
      isModal={isModal}
      mode={mode}
      handleChangeMode={handleChangeMode}
      isHomePage={isHomePage}
      showDisclaimer={!showSearchBudgetButton}
      showTitle={(isModal && isMobile) || !showSearchBudgetButton}
    />
  );
};
