import Button from '@/components/atoms/Button/Button';
import { Switcher } from '@/components/atoms/Switcher/Switcher';
import { ToolTip } from '@/components/atoms/ToolTip/ToolTip';
import {
  CALCULATOR_DISCLAIMER,
  CALCULATOR_DISCLAIMER_LONG,
} from '@/lib/constants';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { ReactElement } from 'react';
import { EstimatedCalculator } from '../EstimatedCalculator';

interface CalculatorWrapperProps {
  isModal?: boolean;
  showTitle?: boolean;
  mode: 'Finance' | 'Lease';
  inputs: ReactElement;
  breakdown: ReactElement;
  handleOfferButton?: () => void;
  monthlyEstimatedPayment: number;
  handleChangeMonthlyEstimatedPayment: (estimatedPayment: string) => void;
  showDisclaimer?: boolean;
  handleChangeMode: (val: 'Finance' | 'Lease') => void;
  isHomePage?: boolean;
}

export const CalculatorWrapper: React.FC<CalculatorWrapperProps> = ({
  isModal,
  showTitle,
  mode,
  handleChangeMode,
  monthlyEstimatedPayment,
  handleChangeMonthlyEstimatedPayment,
  handleOfferButton,
  showDisclaimer,
  inputs,
  breakdown,
  isHomePage,
}) => {
  return (
    <div
      className={`flex h-full w-full rounded-t-small bg-white ${
        isModal
          ? 'flex-col l:flex-row'
          : 'flex-col rounded-b-small border border-neutral-200 ml:flex-row'
      } `}
    >
      <div
        className={`flex flex-col gap-s rounded-t-small p-l l:rounded-l-small l:p-xl l:rounded-tr-none${
          isModal ? 'w-full l:w-1/2' : 'w-full ml:w-1/2 l:w-1/3'
        }`}
      >
        {showTitle && <div className="text-h3SemiBold">Payment Calculator</div>}
        {process.env.NEXT_PUBLIC_SHOW_LEASE_CALC === 'true' && (
          <div className="py-l">
            <Switcher
              aria-label="Finance or Lease"
              options={[
                {
                  text: 'Finance',
                  value: 'Finance',
                },
                { text: 'Lease', value: 'Lease' },
              ]}
              value={mode}
              onChange={(val) => {
                handleChangeMode(val as 'Finance' | 'Lease');
              }}
            />
          </div>
        )}
        {inputs}
      </div>
      <div
        className={`flex w-full flex-col rounded-b-small ${
          isModal ? 'w-full l:w-1/2' : 'w-full l:w-1/2 l:w-2/3'
        }`}
      >
        <div
          className={`rounded-[0px] bg-neutralsGrey-200 px-xl py-l ${
            isModal ? 'l:!rounded-tr-small' : 'ml:!rounded-tr-small'
          }`}
        >
          <EstimatedCalculator
            title={isHomePage ? 'Estimated Budget' : 'Total'}
            monthlyEstimatedPayment={monthlyEstimatedPayment}
            changeMonthlyEstimatedPayment={handleChangeMonthlyEstimatedPayment}
            editable={isModal}
          />
        </div>
        <div
          className={`flex h-full flex-col rounded-b-small bg-white px-xl py-xl l:rounded-bl-[0px] l:rounded-br-small ${
            isModal ? 'pt-xl' : 'py-xl'
          }`}
        >
          {breakdown}
          <div className="flex h-full flex-col justify-end py-l">
            {handleOfferButton && (
              <div className="flex w-full">
                <Button aria-label="Send Offer" onClick={handleOfferButton}>
                  Send Offer
                </Button>
              </div>
            )}
            {showDisclaimer && (
              <span className="pt-l text-microLight text-neutral-800">
                {CALCULATOR_DISCLAIMER}{' '}
                <ToolTip
                  aria-label="View discalimer tool tip"
                  hoverElement={
                    <div className="text-microMedium text-blue-medium">
                      View disclaimer
                    </div>
                  }
                >
                  {CALCULATOR_DISCLAIMER_LONG}
                </ToolTip>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const BreakdownSectionDivider: React.FC<{ strong?: boolean }> = ({
  strong,
}) => {
  return (
    <div
      className={`h-px ${strong ? 'bg-neutral-800' : 'bg-neutral-200'} rounded-small`}
    />
  );
};

export const BreakdownItem: React.FC<{
  label: string;
  tooltip?: string;
  val: string;
}> = ({ label, tooltip, val }) => {
  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-xs">
        <div className="text-body2Light text-neutral-900">{label}</div>
        {tooltip ? (
          <ToolTip
            aria-label={`${label} tooltip`}
            hoverElement={
              <InformationCircleIcon className="size-l fill-neutral-800" />
            }
          >
            <div>{tooltip}</div>
          </ToolTip>
        ) : null}
      </div>
      <div className="text-body2Light text-neutral-900">{val}</div>
    </div>
  );
};

export const Total: React.FC<{
  label: string;
  monthlyTotal: number;
  overallTotal: number;
}> = ({ label, monthlyTotal, overallTotal }) => {
  return (
    <div className="flex justify-between">
      <div className="text-body2Medium text-neutral-900">{label}</div>
      <div className="text-body2Light text-neutral-900">
        ${Math.round(overallTotal).toLocaleString()} or{' '}
        <span className="text-body2Medium text-neutral-900">
          ${Math.round(monthlyTotal).toLocaleString()}/mo
        </span>
      </div>
    </div>
  );
};
