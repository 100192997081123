import { ChevronDownIcon } from '@heroicons/react/24/solid';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useEffect } from 'react';

export interface DropdownProps {
  label?: string;
  options: { text: string; value: string }[];
  disabled?: boolean;
  valueIndex: number;
  onChange: (index: number) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  label,
  valueIndex,
  disabled,
  onChange,
}) => {
  const [open, setOpen] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.body.style.setProperty('overflow', 'visible', 'important');
    return () => {
      document.body.style.setProperty('overflow', 'visible', 'important');
    };
  }, [open]);

  const className = `flex w-full flex-col bg- ${
    disabled
      ? 'pointer-events-none text-neutral-600 cursor-default'
      : 'text-neutral-800 cursor-pointer'
  }
  `;

  return (
    <div className={className} ref={containerRef}>
      {label && (
        <div className="pb-s text-body2Regular text-neutral-800">{label}</div>
      )}
      <div className="flex w-full rounded-xsmall border border-neutral-300 bg-white p-m">
        <DropdownMenu.Root open={open} onOpenChange={setOpen}>
          <DropdownMenu.Trigger className="flex w-full">
            <div className="flex w-full items-center justify-between text-body1Light">
              <div>{options[valueIndex].text}</div>

              <div className="flex h-[16px] w-[16px] text-neutral-800">
                <ChevronDownIcon
                  className={`flex ${open ? 'rotate-180 transform' : ''} transition duration-250`}
                />
              </div>
            </div>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            sideOffset={13}
            className="h-content modal-scroll-bar z-50 flex max-h-[300px] w-full flex-col justify-between gap-xs overflow-auto rounded-xsmall bg-neutral-0 py-xs shadow-l"
            style={{
              width: containerRef.current?.clientWidth,
            }}
          >
            {options.map((option, index) => {
              return (
                <DropdownMenu.Item
                  key={option.value}
                  onSelect={() => onChange(index)}
                  className="flex w-full cursor-pointer items-center !border-none px-xs focus:!border-none"
                  onClick={(e) => {
                    onChange(index);
                    setOpen(false);
                    e.preventDefault();
                  }}
                >
                  <div
                    className={`flex w-full rounded-xsmall px-s py-[9px] hover:bg-neutral-200 ${index === valueIndex ? '!bg-blue-light' : ''}`}
                  >
                    {option.text}
                  </div>
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </div>
  );
};
