export const calculatedMonthlyPayment = (
  pApr: number,
  pTotalAfterDeductions: number,
  pLoanTerm: number,
  fuelSavings?: { estimate: number; timePeriod: number }
) => {
  const rate = pApr / 100;

  let monthlyPayment: number;
  if (pApr > 0) {
    monthlyPayment = Math.max(
      0,
      (pTotalAfterDeductions * (rate / 12)) /
        (1 - (1 + rate / 12) ** -pLoanTerm)
    );
  } else {
    monthlyPayment = Math.max(0, pTotalAfterDeductions / Number(pLoanTerm));
  }

  if (fuelSavings) {
    monthlyPayment -= fuelSavings.estimate / (fuelSavings.timePeriod * 12);
  }

  return monthlyPayment;
};

const getMoneyFactor = (interestRate: number) => {
  return interestRate / 2400;
};

export const calculatedMonthlyPaymentLease = (
  pApr: number,
  pTotalAfterDeductions: number,
  pLeaseTerm: number,
  pResidualValue: number,
  fuelSavings?: { estimate: number; timePeriod: number }
) => {
  const monthlyDepreciation =
    (pTotalAfterDeductions - pResidualValue) / pLeaseTerm;
  const monthlyFinanceCharge =
    (pTotalAfterDeductions + pResidualValue) * getMoneyFactor(pApr);
  let monthlyPayment = monthlyDepreciation + monthlyFinanceCharge;

  if (fuelSavings) {
    monthlyPayment -= fuelSavings.estimate / (fuelSavings.timePeriod * 12);
  }

  return Math.max(0, monthlyPayment);
};
