import { ToolTip } from '@/components/atoms/ToolTip/ToolTip';
import { calculatedMonthlyPayment } from '@/lib/calculatedMonthlyPayment';
import { parseRawListingPrice } from '@/lib/parseVehicle';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@heroicons/react/24/solid';
import { LOAN_TERM_OPTIONS } from '@/components/molecules/PaymentCalculator/PaymentCalculator';

interface ProductListingLinkPriceProps {
  isListedPrice: boolean;
  price: { displayValue: string; value: number | null };
  rebateAmount: number;
  monetaryIncentiveAmount: number;
  totalEstFuelSavings: number;
  displayIncentivesAmount: boolean;
  displayRebatesAmount: boolean;
  displayFuelSavingsAmount: boolean;
}

export const ProductListingLinkPrice: React.FC<
  ProductListingLinkPriceProps
> = ({
  isListedPrice,
  price,
  rebateAmount,
  monetaryIncentiveAmount,
  totalEstFuelSavings,
  displayIncentivesAmount,
  displayRebatesAmount,
  displayFuelSavingsAmount,
}) => {
  if (!price.value) {
    return (
      <>
        <section className="flex flex-col gap-xs">
          <div className="flex items-end gap-s">
            <p className="text-h4SemiBold text-neutral-900">
              {price.displayValue}
            </p>
          </div>
        </section>
      </>
    );
  }

  const monthlyPayment = Math.round(
    calculatedMonthlyPayment(5, price.value * 0.8, Number(LOAN_TERM_OPTIONS[3]))
  );

  let totalIncentiveAmount = monetaryIncentiveAmount + rebateAmount;
  let displayText = 'Max. Incentives & Rebates';
  let secondaryDisplayText = '';
  let secondaryAmount = 0;
  if (!displayRebatesAmount && displayIncentivesAmount) {
    totalIncentiveAmount = monetaryIncentiveAmount;
    displayText = 'Max. Incentives';
    secondaryDisplayText = 'Max. Rebates';
    secondaryAmount = rebateAmount;
  } else if (displayRebatesAmount && !displayIncentivesAmount) {
    totalIncentiveAmount = rebateAmount;
    displayText = 'Max. Rebates';
    secondaryDisplayText = 'Max. Incentives';
    secondaryAmount = monetaryIncentiveAmount;
  } else if (!displayRebatesAmount && !displayIncentivesAmount) {
    secondaryDisplayText = 'Max. Incentives & Rebates';
    displayText = '';
    secondaryAmount = monetaryIncentiveAmount + rebateAmount;
  }

  const incentiveDiscountObject = parseRawListingPrice(totalIncentiveAmount);
  const incentiveDiscountValue = incentiveDiscountObject.value || 0;
  const fuelSavingsDiscountObject = parseRawListingPrice(totalEstFuelSavings);
  const fuelSavingsDiscountValue = fuelSavingsDiscountObject.value || 0;
  const incentiveDiscountMonthlyPayment = Math.round(
    calculatedMonthlyPayment(
      5,
      price.value * 0.8 - incentiveDiscountValue,
      Number(LOAN_TERM_OPTIONS[3])
    )
  );

  const fuelSavingsDiscountMonthlyPayment = Math.round(
    calculatedMonthlyPayment(
      5,
      price.value * 0.8 - fuelSavingsDiscountValue,
      96
    )
  );

  const incentiveAndFuelDiscountMonthlyPayment = Math.round(
    calculatedMonthlyPayment(
      5,
      price.value * 0.8 - incentiveDiscountValue - fuelSavingsDiscountValue,
      96
    )
  );

  const displayDiscountPriceIncentives = parseRawListingPrice(
    price.value - incentiveDiscountValue
  ).displayValue;

  const displayDiscountPriceFuelSavings = parseRawListingPrice(
    price.value - fuelSavingsDiscountValue
  ).displayValue;

  const displayDiscountPriceIncentivesAndFuel = parseRawListingPrice(
    price.value - incentiveDiscountValue - fuelSavingsDiscountValue
  ).displayValue;

  const incentivesAndFuelSavingsText = [
    'The displayed price includes the maximum estimated incentives, rebates, and fuel savings on this EV compared to the gasoline equivalent car.',
  ];
  const noIncentivesAndFuelSavingsText = [
    'The displayed price excludes incentives, rebates, and fuel savings.',
  ];
  const incentivesOnlyText = [
    'The displayed price includes the maximum estimated incentives and rebates. ',
    'The displayed price excludes fuel savings estimates on this EV compared to the gasoline equivalent car.',
  ];
  const fuelSavingsOnlyText = [
    'The displayed price excludes incentives and rebates.',
    'The displayed price includes fuel savings estimates on this EV compared to the gasoline equivalent car.',
  ];
  const noQualifyingIncentivesText = [
    'The displayed price includes fuel savings estimates on this EV compared to the gasoline equivalent car.',
  ];

  let monthlyValue = `$${monthlyPayment.toLocaleString()}`;
  let { displayValue } = price;
  let displayDisclaimer = noIncentivesAndFuelSavingsText;
  const displayDiscountedAmounts =
    displayIncentivesAmount || displayRebatesAmount;

  if (displayDiscountedAmounts && displayFuelSavingsAmount) {
    monthlyValue = `$${incentiveAndFuelDiscountMonthlyPayment.toLocaleString()}`;
    displayValue = displayDiscountPriceIncentivesAndFuel;
    displayDisclaimer = incentivesAndFuelSavingsText;
  } else if (displayDiscountedAmounts) {
    monthlyValue = `$${incentiveDiscountMonthlyPayment.toLocaleString()}`;
    displayValue = displayDiscountPriceIncentives;
    displayDisclaimer = incentivesOnlyText;
  } else if (displayFuelSavingsAmount) {
    monthlyValue = `$${fuelSavingsDiscountMonthlyPayment.toLocaleString()}`;
    displayValue = displayDiscountPriceFuelSavings;
    displayDisclaimer = fuelSavingsOnlyText;
  }

  if (rebateAmount === 0 && totalIncentiveAmount === 0) {
    displayDisclaimer = noQualifyingIncentivesText;
  }

  return (
    <>
      <section className="flex flex-col gap-xs">
        <div className="flex items-end gap-s">
          <p className="text-h4SemiBold text-neutral-900">
            {isListedPrice ? displayValue : monthlyValue}
            {!isListedPrice && <span className="text-body1Regular">/mo</span>}
          </p>
          <ToolTip
            aria-label="Price savings disclosure tooltip"
            hoverElement={
              <InformationCircleIcon className="mb-0.5 h-[16px] w-[16px] text-neutral-600" />
            }
          >
            <div>
              {displayDisclaimer.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
              <p>
                All prices exclude taxes and fees. Prices and estimates are
                subject to change.
              </p>
            </div>
          </ToolTip>
        </div>
        {totalIncentiveAmount !== 0 && displayText && (
          <div className="flex items-center gap-xs text-microLight text-blue-medium">
            <CheckIcon className="h-[12px] w-[12px] text-blue-medium" />
            <div>{displayText} </div>
            <div className="text-microMedium">
              -$
              {Intl.NumberFormat('en-US').format(Number(totalIncentiveAmount))}
            </div>
          </div>
        )}
        {secondaryAmount !== 0 && secondaryDisplayText && (
          <div className="flex items-center gap-xs text-microLight text-neutral-800">
            <div>{secondaryDisplayText} </div>
            <div>
              ${Intl.NumberFormat('en-US').format(Number(secondaryAmount))}
            </div>
          </div>
        )}
        {totalEstFuelSavings !== 0 && (
          <div
            className={`flex items-center gap-xs text-microLight ${displayFuelSavingsAmount ? 'text-green-dark' : 'text-neutral-800'}`}
          >
            {displayFuelSavingsAmount && (
              <CheckIcon className="h-[12px] w-[12px] text-green-dark" />
            )}
            <div>Est. Fuel savings </div>
            <div>
              <span
                className={`${displayFuelSavingsAmount ? 'text-microMedium' : ''}`}
              >
                {displayFuelSavingsAmount && '-'}$
                {Intl.NumberFormat('en-US').format(Number(totalEstFuelSavings))}
              </span>
              <span>/year</span>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
