import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import { haversineDistance } from '@/lib/calculateDistance';
import { validateUSZipCode } from '@/lib/validators';
import { FilterState, GeneralRawDealerType } from '@/types';
import { MapPinIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { useEffect, useState } from 'react';

interface ProductListingLinkFooterProps {
  dealerInfo: GeneralRawDealerType;
  isColumnLayout?: boolean;
  filters?: FilterState;
}

export const ProductListingLinkFooter: React.FC<
  ProductListingLinkFooterProps
> = ({ dealerInfo, filters }) => {
  const [distance, setDistance] = useState<number | null>(0);

  useEffect(() => {
    const getDistance = async () => {
      const response = await fetch(
        `/api/distance?zipCode1=${filters?.fields.postal}&zipCode2=${dealerInfo.PostalCode}`
      );
      if (!response.ok) {
        setDistance(null);
      }
      const data = await response.json();
      const { coords1, coords2 } = data;
      const distanceInMiles = haversineDistance(coords1, coords2);
      setDistance(distanceInMiles);
    };
    if (
      filters?.fields.postal &&
      validateUSZipCode(filters?.fields.postal) &&
      filters.shouldRefetch
    ) {
      getDistance();
    }
  }, [dealerInfo, filters]);

  return (
    <div className="flex w-full items-center justify-between gap-xl px-xl py-l">
      {dealerInfo?.Dealership && (
        <Link
          aria-label={`redirect to ${dealerInfo.Dealership} dealer profile`}
          href={`/dealer/${dealerInfo?.DealerID}`}
          className={`flex-1 truncate text-microLight text-neutral-800 underline ${
            process.env.NEXT_PUBLIC_ENABLE_DEALER_PROFILE === 'true'
              ? ''
              : 'pointer-events-none'
          }`}
        >
          {dealerInfo.Dealership.includes('Hertz') ? (
            <>
              <label className="sr-only">{dealerInfo.Dealership}</label>
              <Icon iconId={IconIds.HertzLogoPrimary} height={16} width={45} />
            </>
          ) : (
            dealerInfo.Dealership
          )}
        </Link>
      )}
      <div
        className={`${
          dealerInfo?.Dealership ? 'flex' : 'w-full'
        } items-center justify-end gap-xs whitespace-nowrap text-microLight text-neutral-800`}
      >
        <MapPinIcon height={16} width={16} />
        {dealerInfo.City}, {dealerInfo.State}{' '}
        {distance ? `(${Math.round(distance)} mi)` : ''}
      </div>
    </div>
  );
};
