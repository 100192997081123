import { Input, InputProps } from '@/components/atoms/Input/Input';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import {
  DropdownMenuGroupType,
  DropdownMenuOptionType,
  FilterType,
} from '@/types';
import { POPULAR_MAKES } from '@/lib/constants';
import { stringMatch } from '@/lib/searchAlgorithms';
import DropdownMenu from '../DropdownMenu';
import { DropdownMenuItemVariants } from '../DropdownMenuItem';

interface SearchBarProps
  extends Pick<InputProps, 'placeholder' | 'append' | 'prepend'> {
  makes: string[];
  models: { [key: string]: string[] };
  input: string;
  setInput: (input: string) => void;
  handleVectorSearch?: (input: string) => void;
  filters?: FilterType;
  isClearingSearchInput?: boolean;
  setIsClearingSearchInput?: (isClearingSearchInput: boolean) => void;
}

const getDefaultMenuItems = () => {
  return POPULAR_MAKES.map((make) => ({
    text: make,
    value: make,
    makeIcon: { make },
  }));
};

export const SearchBar: React.FC<SearchBarProps> = ({
  makes,
  models,
  input = '',
  setInput,
  placeholder = 'Search by make or model',
  append,
  prepend,
  handleVectorSearch = () => {},
  filters,
  isClearingSearchInput = false,
  setIsClearingSearchInput = () => {},
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState<
    DropdownMenuOptionType[] | DropdownMenuGroupType[]
  >(getDefaultMenuItems());

  useEffect(() => {
    if (filters?.vector_search === '') {
      setInput('');
    }
  }, [filters?.vector_search, setInput]);

  const getSuggestions = useCallback(
    (
      newInput: string,
      makesList: string[],
      modelsList: { [key: string]: string[] }
    ) => {
      let finalMenuItems = [];

      finalMenuItems = stringMatch(newInput, makesList, modelsList);

      if (finalMenuItems.length === 0 || newInput.length === 0) {
        finalMenuItems = getDefaultMenuItems();
      }

      setDropdownOptions(finalMenuItems);
    },
    []
  );

  useEffect(() => {
    const clickOutside = (evt: MouseEvent) => {
      if (!evt.target || !containerRef.current) {
        return;
      }
      if (
        inputRef.current === evt.target ||
        inputRef.current?.contains(evt.target as Node)
      ) {
        setShowOptions(!showOptions);
      } else if (!containerRef.current.contains(evt.target as Node)) {
        setShowOptions(false);
      }
      if (
        !isClearingSearchInput &&
        input !== filters?.vector_search &&
        window.location.pathname !== '/'
      ) {
        handleVectorSearch(input);
      }
      setIsClearingSearchInput(false);
    };
    const handlePressEnter = (evt: KeyboardEvent) => {
      if (evt.key === 'Enter' && input !== filters?.vector_search) {
        if (window.location.pathname !== '/') {
          handleVectorSearch(input);
        }
        setShowOptions(false);
        evt.preventDefault();
      }
    };
    document.addEventListener('keydown', handlePressEnter);
    document.addEventListener('click', clickOutside);

    return () => {
      document.removeEventListener('click', clickOutside);
      document.removeEventListener('keydown', handlePressEnter);
    };
  }, [
    showOptions,
    dropdownOptions,
    setInput,
    handleVectorSearch,
    input,
    filters?.vector_search,
    isClearingSearchInput,
    setIsClearingSearchInput,
  ]);

  const handleChangeInput = useCallback(
    (newInput: string) => {
      setInput(newInput);
      getSuggestions(newInput, makes, models);
    },
    [makes, models, setInput, getSuggestions]
  );

  const handleDropdownSelect = useCallback(
    (selected: string) => {
      handleChangeInput(selected);
      setInput(selected);
    },
    [handleChangeInput, setInput]
  );

  return (
    <div ref={containerRef} className="relative flex w-full">
      <div ref={inputRef} className="flex-grow">
        <Input
          aria-label="Search bar"
          id="search-bar"
          placeholder={placeholder}
          value={input}
          onChange={(e) => handleChangeInput(e)}
          autoComplete="off"
          append={append}
          prepend={prepend}
        />
      </div>

      <div className="absolute top-[100%] flex w-full">
        <DropdownMenu
          open={showOptions}
          className={`${showOptions ? 'flex flex-grow' : 'hidden'}`}
          options={dropdownOptions}
          value={[]}
          onChange={(val) => {
            setShowOptions(false);
            handleDropdownSelect(val[0]);
          }}
          variant={DropdownMenuItemVariants.NoSelect}
        />
      </div>
    </div>
  );
};
