import { JDPowerIncentive } from '@/types';
import { FC, useEffect, useState } from 'react';

export interface BaseEstimatedCalculatorProps {
  monthlyEstimatedPayment: number;
  apr?: number;
}

interface EstimatedCalculatorProps extends BaseEstimatedCalculatorProps {
  title?: string;
  changeMonthlyEstimatedPayment?: (value: string) => void;
  editable?: boolean;
  selectedIncentives?: JDPowerIncentive[];
  showTotal?: boolean;
}

export const EstimatedCalculator: FC<EstimatedCalculatorProps> = ({
  title,
  monthlyEstimatedPayment,
  changeMonthlyEstimatedPayment,
  editable = true,
  showTotal = true,
}) => {
  const [monthlyPayment, setMonthlyPayment] = useState(monthlyEstimatedPayment);
  useEffect(() => {
    if (changeMonthlyEstimatedPayment) {
      setMonthlyPayment(monthlyEstimatedPayment);
    }
  }, [changeMonthlyEstimatedPayment, monthlyEstimatedPayment]);

  return (
    <div className="flex flex-col gap-s">
      {title && <div className="text-h4SemiBold text-neutral-900">{title}</div>}

      {showTotal && (
        <div className="flex items-center justify-between">
          {changeMonthlyEstimatedPayment && (
            <span className="flex text-h2SemiBold m:text-h1SemiBold">
              $
              <div className="relative flex">
                <div className={`pointer-events-none absolute`}>
                  <span className={`flex h-full items-baseline text-body3`}>
                    <span
                      className={`text-h2SemiBold m:text-h1SemiBold ${editable ? 'text-transparent' : ''}`}
                    >
                      {monthlyPayment.toFixed(0)}
                    </span>
                    <span className="text-h3SemiBold">/mo</span>
                  </span>
                </div>
              </div>
              {editable && (
                <>
                  <label className="sr-only" htmlFor="monthly-payment">
                    Monthly Payment
                  </label>
                  <input
                    id="monthly-payment"
                    className={`input no-buttons w-full bg-transparent text-h2SemiBold m:text-h1SemiBold`}
                    type="number"
                    value={monthlyEstimatedPayment.toFixed(0)}
                    onChange={(evt) => {
                      changeMonthlyEstimatedPayment(evt.currentTarget.value);
                    }}
                  />
                </>
              )}
            </span>
          )}
          {!changeMonthlyEstimatedPayment && (
            <div className="text-h2SemiBold">
              ${monthlyEstimatedPayment.toFixed(0)}
              <span className="text-h3SemiBold">/mo</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
