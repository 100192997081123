import { Input, InputVariants } from '@/components/atoms/Input/Input';
import { Switcher } from '@/components/atoms/Switcher/Switcher';
import useWindowSize from '@/hooks/useWindowSize';
import { calculatedMonthlyPayment } from '@/lib/calculatedMonthlyPayment';
import { calculateMSRP } from '@/lib/calculateMSRP';
import { INCENTIVES_DISCLAIMER } from '@/lib/constants';
import { PaymentCalculatorProps } from '@/types';
import { useCallback, useEffect, useState } from 'react';
import Accordion from '../Accordion';
import {
  BreakdownItem,
  BreakdownSectionDivider,
  CalculatorWrapper,
  Total,
} from './Wrappers';

interface FinanceCalculatorProps extends PaymentCalculatorProps {
  mode?: 'Finance' | 'Lease';
  setMode?: (val: 'Finance' | 'Lease') => void;
  downPaymentType: string;
  setDownPaymentType: (val: '%' | '$') => void;
  downPayment: number;
  setDownPayment: (val: number) => void;
  downPaymentPercent: number;
  setDownPaymentPercent: (val: number) => void;
  salePrice: number;
  setSalePrice: (val: number) => void;
  tradeInValue: number;
  setTradeInValue: (val: number) => void;
  loanTermOptions: string[];
  loanTerm: string;
  setLoanTerm: (val: string) => void;
  apr: number;
  setApr: (val: number) => void;
  monthlyBudget: number;
  setMonthlyBudget: (val: number) => void;
  incentivesTotal: number;
}

export const FinanceCalculator = ({
  expanded = true,
  setExpanded = () => {},
  isModal = false,
  showSearchBudgetButton = true,
  onChangeApr,
  onChangeMonthlyEstimatedPayment,
  setCalculatedMaxMonthlyPayment,
  setCalculatedMSRP,
  enteredMaxMonthlyPayment = '',
  fixedPrice = true,
  handleOfferButton,
  downPaymentType,
  setDownPaymentType,
  downPayment,
  setDownPayment,
  downPaymentPercent,
  setDownPaymentPercent,
  salePrice,
  setSalePrice,
  tradeInValue,
  setTradeInValue,
  loanTermOptions,
  loanTerm,
  setLoanTerm,
  apr,
  setApr,
  monthlyBudget,
  setMonthlyBudget,
  incentivesTotal,
  mode = 'Finance',
  setMode,
  isHomePage,
  fuelSavings,
  onChangeTerm,
}: FinanceCalculatorProps) => {
  const { isTablet, isMobile } = useWindowSize();

  const calculateMonthlyEstimatedPayment = useCallback(
    (pApr: number, pTotalAfterDeductions: number, pLoanTerm: number) => {
      return calculatedMonthlyPayment(
        pApr,
        pTotalAfterDeductions,
        pLoanTerm,
        fuelSavings
          ? {
              estimate: Number(fuelSavings.fuelSavingsEstimate),
              timePeriod: Number(fuelSavings.timePeriod),
            }
          : undefined
      );
    },
    [fuelSavings]
  );

  const calculateSalePrice = useCallback(
    (pMonthlyEstimatedPayment: number, pApr: number, pLoanTerm: number) => {
      return calculateMSRP(
        pMonthlyEstimatedPayment,
        pApr,
        pLoanTerm,
        downPayment,
        tradeInValue,
        incentivesTotal
      );
    },
    [downPayment, tradeInValue, incentivesTotal]
  );

  const calculateTotalAfterDeductions = useCallback(
    (
      pSalePrice: number,
      pDownPayment: number,
      pTradeInValue = 0,
      pIncentivesTotal = 0
    ) => {
      let currentTotal = 0;
      if (pSalePrice > 0) {
        currentTotal = pSalePrice - pDownPayment - pTradeInValue;
        if (pIncentivesTotal > 0) {
          currentTotal -= pIncentivesTotal;
        }
      }
      return currentTotal;
    },
    []
  );

  const [totalAfterDeductions, setTotalAfterDeductions] = useState<number>(
    calculateTotalAfterDeductions(
      salePrice,
      downPayment,
      tradeInValue,
      incentivesTotal
    )
  );

  useEffect(() => {
    setTotalAfterDeductions(
      calculateTotalAfterDeductions(
        salePrice,
        downPayment,
        tradeInValue,
        incentivesTotal
      )
    );
  }, [
    incentivesTotal,
    salePrice,
    downPayment,
    tradeInValue,
    calculateTotalAfterDeductions,
  ]);

  const [monthlyEstimatedPayment, setMonthlyEstimatedPayment] =
    useState<number>(
      enteredMaxMonthlyPayment
        ? Number(enteredMaxMonthlyPayment)
        : calculateMonthlyEstimatedPayment(
            apr,
            totalAfterDeductions,
            Number(loanTerm)
          )
    );

  const handleChangeMonthlyEstimatedPayment = useCallback(
    (inputMonthlyEstimatedPayment: string) => {
      setMonthlyEstimatedPayment(Number(inputMonthlyEstimatedPayment));
      setCalculatedMaxMonthlyPayment?.(inputMonthlyEstimatedPayment);

      const newSP = calculateSalePrice(
        Number(inputMonthlyEstimatedPayment),
        apr,
        Number(loanTerm)
      );

      setSalePrice(newSP);
      setCalculatedMSRP?.(newSP.toString());
      const newTotalAfterDeductions = calculateTotalAfterDeductions(
        newSP,
        downPayment,
        tradeInValue,
        incentivesTotal
      );
      setTotalAfterDeductions(newTotalAfterDeductions);
    },

    [
      setCalculatedMaxMonthlyPayment,
      calculateSalePrice,
      apr,
      loanTerm,
      setSalePrice,
      setCalculatedMSRP,
      calculateTotalAfterDeductions,
      downPayment,
      tradeInValue,
      incentivesTotal,
    ]
  );

  const updateInputs = useCallback(
    (
      newSalePrice: number,
      newTotalAfterDeductions: number,
      newApr: number,
      newLoanTerm: number
    ) => {
      if (newSalePrice >= 0) {
        const newMonthlyEstimatedPayment = calculateMonthlyEstimatedPayment(
          newApr,
          newTotalAfterDeductions,
          newLoanTerm
        );

        setMonthlyEstimatedPayment(newMonthlyEstimatedPayment);
        setCalculatedMaxMonthlyPayment?.(
          Math.round(newMonthlyEstimatedPayment).toString()
        );
      }
    },
    [calculateMonthlyEstimatedPayment, setCalculatedMaxMonthlyPayment]
  );

  // Shortcut to get fuel savings to update once GVE calculator is rendered
  useEffect(() => {
    if (fuelSavings) {
      updateInputs(salePrice, totalAfterDeductions, apr, Number(loanTerm));
    }
  });

  const handleChangeSalePrice = useCallback(
    (pSalePrice: string) => {
      setSalePrice(Number(pSalePrice));
      setCalculatedMSRP?.(pSalePrice);
      const newTotalAfterDeductions = calculateTotalAfterDeductions(
        Number(pSalePrice),
        downPayment,
        tradeInValue,
        incentivesTotal
      );
      setTotalAfterDeductions(newTotalAfterDeductions);
      updateInputs(
        Number(pSalePrice),
        newTotalAfterDeductions,
        apr,
        Number(loanTerm)
      );
    },
    [
      setSalePrice,
      setCalculatedMSRP,
      calculateTotalAfterDeductions,
      downPayment,
      tradeInValue,
      incentivesTotal,
      updateInputs,
      apr,
      loanTerm,
    ]
  );

  const handleChangeApr = useCallback(
    (pApr: string) => {
      const newApr = Number(pApr);
      if (newApr <= 100) {
        setApr(newApr);
        onChangeApr?.(newApr);
        const newTotalAfterDeductions = calculateTotalAfterDeductions(
          salePrice,
          downPayment,
          tradeInValue,
          incentivesTotal
        );
        setTotalAfterDeductions(newTotalAfterDeductions);

        updateInputs(
          salePrice,
          newTotalAfterDeductions,
          newApr,
          Number(loanTerm)
        );
      }
    },
    [
      setApr,
      onChangeApr,
      calculateTotalAfterDeductions,
      salePrice,
      downPayment,
      tradeInValue,
      incentivesTotal,
      updateInputs,
      loanTerm,
    ]
  );

  const handleChangeDownPayment = useCallback(
    (pDownPayment: string) => {
      let value = Number(pDownPayment);
      if (downPaymentType === '$') {
        setDownPayment(Number(value.toFixed(0)));
      } else if (value <= 100) {
        setDownPaymentPercent(value);
        value = Math.floor((value / 100) * salePrice);
        setDownPayment(value);
      }
      const newTotalAfterDeductions = calculateTotalAfterDeductions(
        salePrice,
        value,
        tradeInValue,
        incentivesTotal
      );
      setTotalAfterDeductions(newTotalAfterDeductions);

      updateInputs(salePrice, newTotalAfterDeductions, apr, Number(loanTerm));
    },
    [
      downPaymentType,
      calculateTotalAfterDeductions,
      salePrice,
      tradeInValue,
      incentivesTotal,
      updateInputs,
      apr,
      loanTerm,
      setDownPayment,
      setDownPaymentPercent,
    ]
  );

  const handleChangeTradeInValue = useCallback(
    (pTradeInValue: string) => {
      setTradeInValue(Number(pTradeInValue));
      const newTotalAfterDeductions = calculateTotalAfterDeductions(
        salePrice,
        downPayment,
        Number(pTradeInValue),
        incentivesTotal
      );
      setTotalAfterDeductions(newTotalAfterDeductions);

      updateInputs(salePrice, newTotalAfterDeductions, apr, Number(loanTerm));
    },
    [
      setTradeInValue,
      calculateTotalAfterDeductions,
      salePrice,
      downPayment,
      incentivesTotal,
      updateInputs,
      apr,
      loanTerm,
    ]
  );

  const handleChangeLoanTerm = useCallback(
    (pLoanTerm: string) => {
      setLoanTerm(pLoanTerm);
      onChangeTerm?.(pLoanTerm);
      updateInputs(salePrice, totalAfterDeductions, apr, Number(pLoanTerm));
    },
    [
      setLoanTerm,
      updateInputs,
      onChangeTerm,
      salePrice,
      totalAfterDeductions,
      apr,
    ]
  );

  const handleChangeDownPaymentType = useCallback(
    (type: string) => {
      setDownPayment(
        Number(((downPaymentPercent * salePrice) / 100).toFixed(0))
      );
      setDownPaymentPercent(
        Math.min(100, Number(((downPayment / salePrice) * 100).toFixed(0)))
      );
      setDownPaymentType(type as '%' | '$');
    },
    [
      downPayment,
      downPaymentPercent,
      salePrice,
      setDownPayment,
      setDownPaymentPercent,
      setDownPaymentType,
    ]
  );

  const handleChangeMode = useCallback(
    (val: 'Finance' | 'Lease') => {
      if (setMode) {
        setMode(val);
      }
    },
    [setMode]
  );

  useEffect(() => {
    let newMonthlyBudget = 0;
    if (Number(loanTerm) > 0) {
      newMonthlyBudget = Math.max(0, salePrice / Number(loanTerm));
    } else {
      newMonthlyBudget = 0;
    }
    setMonthlyBudget(newMonthlyBudget);
  }, [loanTerm, salePrice, setMonthlyBudget]);

  useEffect(() => {
    if (onChangeMonthlyEstimatedPayment) {
      onChangeMonthlyEstimatedPayment(monthlyEstimatedPayment);
    }
  }, [monthlyEstimatedPayment, onChangeMonthlyEstimatedPayment]);

  const inputs = (
    <div className="flex flex-col gap-s">
      {fixedPrice ? (
        <Input
          id="salePrice"
          label="Listed price"
          aria-label="Listed price"
          type={InputVariants.Filled}
          value={`$${salePrice.toString()}`}
        />
      ) : (
        <Input
          type={InputVariants.Number}
          aria-label="Listed price"
          value={salePrice.toString()}
          onChange={(val) => handleChangeSalePrice(val)}
          label="Listed price"
          prepend="$"
          placeholder="0"
          step={1000}
        />
      )}
      <Input
        type={InputVariants.Number}
        aria-label="Est. APR"
        value={apr.toString()}
        onChange={(val) => handleChangeApr(val)}
        label={'Est. APR %'}
        append="%"
        placeholder="8.50"
        step={0.01}
      />

      <Input
        type={InputVariants.Number}
        aria-label="Down Payment"
        value={
          downPaymentType === '$'
            ? downPayment.toString()
            : downPaymentPercent.toString()
        }
        onChange={(val) => handleChangeDownPayment(val)}
        invalid={
          (downPaymentType === '$' && downPayment >= salePrice) ||
          (downPaymentType === '%' &&
            (downPaymentPercent >= 100 || downPaymentPercent < 0))
        }
        label="Down Payment"
        float={0}
        placeholder="0"
        suffixOption={{
          value: downPaymentType,
          options: [
            { label: '$', value: '$' },
            { label: '%', value: '%' },
          ],
          setSuffixOption: (val) => handleChangeDownPaymentType(val),
        }}
      />
      {((downPaymentType === '$' && downPayment >= salePrice) ||
        (downPaymentType === '%' &&
          (downPaymentPercent >= 100 || downPaymentPercent < 0))) && (
        <div className="text-microMedium text-red-medium">
          Must be less than sale price
        </div>
      )}

      <Input
        aria-label="Est. Trade-in Value"
        type={InputVariants.Number}
        value={tradeInValue.toString()}
        onChange={(val) => handleChangeTradeInValue(val)}
        invalid={tradeInValue > salePrice}
        label="Est. Trade-in Value"
        prepend="$"
        placeholder="0"
        step={1000}
      />
      {tradeInValue > salePrice && (
        <div className="text-microMedium text-red-medium">
          Must be less than sale price
        </div>
      )}

      <div>
        <div className="label mb-xs block text-body2Medium text-neutral-800">
          Loan Term
        </div>
        <Switcher
          aria-label="Loan Term"
          options={loanTermOptions.map((option) => ({
            text: option,
            value: option,
          }))}
          value={loanTerm}
          onChange={(e) => handleChangeLoanTerm(e)}
        />
      </div>
    </div>
  );

  const breakdown = (
    <div className="flex flex-col gap-s">
      <div className="flex flex-col gap-xs">
        <BreakdownItem
          label="Listed price"
          val={`$${salePrice.toLocaleString()}`}
        />
        <BreakdownItem
          label="Monthly budget"
          val={`$${monthlyBudget.toFixed(0).toLocaleString()}/mo`}
        />
      </div>

      <BreakdownSectionDivider />

      <BreakdownItem
        label="Down Payment"
        val={`${downPayment > 0 ? '-' : ''}$${downPayment.toLocaleString()}`}
      />
      <BreakdownItem
        label="Est. Trade-In Value"
        val={`${tradeInValue > 0 ? '-' : ''}$${tradeInValue.toLocaleString()}`}
      />

      {incentivesTotal || fuelSavings ? <BreakdownSectionDivider /> : null}

      {incentivesTotal ? (
        <BreakdownItem
          label="Incentives and Rebates"
          val={`${incentivesTotal > 0 ? '-' : ''}$${
            incentivesTotal > 0 ? incentivesTotal.toLocaleString() : 0
          }`}
          tooltip={INCENTIVES_DISCLAIMER}
        />
      ) : null}
      {fuelSavings ? (
        <BreakdownItem
          label={`Fuel Savings over ${Number(loanTerm) / 12} years`}
          val={`-$${(
            (Number(fuelSavings.fuelSavingsEstimate) *
              (Number(loanTerm) / 12)) /
            Number(fuelSavings.timePeriod)
          ).toLocaleString()}`}
          tooltip={`Efficiency assessments are guided by EPA standards, factoring in 45% highway and 55% city driving for ${Intl.NumberFormat('en-US').format(Number(fuelSavings.annualMiles))} annual miles. Costs are based on national averages: $${Number(fuelSavings.gasPerGallon).toFixed(2)} per gallon of gasoline and $${Number(fuelSavings.electricityPerKwh).toFixed(2)} per kWh for electricity. Actual savings may vary due to market changes and driving conditions. Consumption ratings are provided for both electric vehicles (in kWh/100mi) and gasoline vehicles (in MPG).`}
        />
      ) : null}

      <BreakdownSectionDivider strong={true} />

      <Total
        label="Total"
        monthlyTotal={monthlyEstimatedPayment}
        overallTotal={monthlyEstimatedPayment * Number(loanTerm)}
      />
    </div>
  );

  return (
    <CalculatorWrapper
      mode={mode}
      inputs={
        isMobile || (isTablet && isModal) ? (
          <Accordion
            title={'Customize Payments'}
            buttonClassName="text-neutral-900"
            icon={{ id: 'chevron' }}
            expanded={expanded}
            setExpanded={setExpanded}
          >
            {inputs}
          </Accordion>
        ) : (
          inputs
        )
      }
      breakdown={breakdown}
      monthlyEstimatedPayment={monthlyEstimatedPayment}
      handleChangeMonthlyEstimatedPayment={handleChangeMonthlyEstimatedPayment}
      handleChangeMode={handleChangeMode}
      handleOfferButton={handleOfferButton}
      isModal={isModal}
      isHomePage={isHomePage}
      showDisclaimer={!showSearchBudgetButton}
      showTitle={(isModal && isMobile) || !showSearchBudgetButton}
    />
  );
};
